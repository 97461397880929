import React, { useCallback, useState } from "react";
import { useRNHandler } from "../../../reactNative/@utility/react-native.utility";
import { textToSpeechAvailableVoicesVar } from "../../../App";

export const RNTextToSpeechVoices = () => {
	const setAvailableVoices = useCallback(
		(payload: {
			voices: {
				id: string;
				name: string;
				language: string;
			}[];
		}) => {
			console.log("--payload.voices--", payload.voices);
			textToSpeechAvailableVoicesVar(payload.voices);
		},
		[]
	);

	useRNHandler("textToSpeechAvailableVoices", setAvailableVoices);

	return null;
};
