import { translate, TxKeyPath } from "../../../i18n";

export enum NotificationGroups {
	Admin = "admin",
	Review = "review",
	Discount = "discount",
	StudyPlan = "study-plan",
	Quiz = "quiz",
	AppHelpCenter = "app-help-center",
	OnlineClass = "online-class",
	Affiliate = "affiliate",
	Subscription = "subscription",
	Streak = "streak",
	NewContent = "new-content",
	Speaking = "speaking",
	Listening = "listening",
	Grammar = "grammar",
	Vocabulary = "vocabulary",
	Reading = "reading",
	AppUpdate = "app-update",
	Poll = "poll",
	NewsIssues = "news-issues",
	SocialNetworks = "social-networks",
	Others = "others",
	// App rate in google play, etc
	// like, follow, etc in social media
	// bug, feedback, etc Report
	// Invite users
}

export const NotificationGroupOptions = () =>
	Object.keys(NotificationGroups).map((key) => ({
		value: NotificationGroups[key as keyof typeof NotificationGroups],
		label: translate(`notifications.groups.${key}` as TxKeyPath),
	}));
