import React from "react";
import { isNotificationEnabled } from "../../notification.utility";
import { Badge } from "antd-mobile";
import { useHistory } from "react-router-dom";
import { RoutesConfig } from "../../../../routes/RoutesConfig";
import clsx from "clsx";
import { IoNotificationsOutline } from "react-icons/io5";
import css from "./NotificationHeaderIcon.module.css";
import { NotificationStatus } from "../../../@admin/notification/notification.types";
import { useGetUserNotificationsCountQuery } from "../../graphQL/get-user-notifications-count/get-user-notifications-count.query";

export const NotificationHeaderIcon = () => {
	const history = useHistory();

	const { loading, data } = useGetUserNotificationsCountQuery({
		variables: {
			status: NotificationStatus.UNREAD,
		},
		pollInterval: 10000,
	});

	const count = data?.getUserNotificationsCount?.totalCount || 0;

	const navigateToNotifList = () => {
		history.push(RoutesConfig.notification.list.path);
	};

	if (!isNotificationEnabled()) {
		return null;
	}

	return (
		<button
			className={clsx("no-style-button", css.notif)}
			onClick={navigateToNotifList}
			type="button"
		>
			<Badge
				className={clsx(count === 0 && css.badgeCircle)}
				content={count === 0 ? Badge.dot : `${count}`}
				bordered
			>
				<IoNotificationsOutline size={24} color="#263238" />
			</Badge>
		</button>
	);
};
