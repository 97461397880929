import { gql } from "@apollo/client";

export const ADMIN_TRANSACTION_FIELDS_FRAGMENT = gql`
	fragment TransactionFields on Transaction {
		id
		amount
		payDate
		transactionCode
		orderId
		status
		createdAt
		updatedAt
		user {
			id
			fullname
			email
			phoneNumber
		}
		plan {
			id
			name
			title
			amount
			duration
			unit
			status
		}
		currency
		commissionPercentage
		taxPercentage
		takehomePercentage
	}
`;
