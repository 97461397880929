import clsx from "clsx";
import React, { forwardRef } from "react";
import css from "./View.module.css";

type Props = {
	children?: React.ReactNode;
	className?: string;
	onClick?: () => void;
	style?: React.CSSProperties;
};

// ForwardRef component
const View = forwardRef<HTMLElement, Props>((props, ref) => {
	const { children, className, onClick, style } = props;

	return (
		<section
			ref={ref} // Forward the ref to the section element
			style={style}
			onClick={onClick}
			className={clsx(css.view, className)}
		>
			{children}
		</section>
	);
});

View.displayName = "View";

export default View;
