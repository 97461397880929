import { gql } from "@apollo/client";

const CB_CONFIRM_TRANSACTION = gql`
	mutation CBConfirmTransaction(
		$purchaseToken: String!
		$productId: String!
	) {
		CBConfirmTransaction(
			purchaseToken: $purchaseToken
			productId: $productId
		) {
			result
			errors
		}
	}
`;

export default CB_CONFIRM_TRANSACTION;
