import { gql } from "@apollo/client";

export const REFERRAL_FIELDS_FRAGMENT = gql`
	fragment ReferralFields on ReferralForAffiliate {
		id
		createdAt
		productPrice
		commissionPercentage
		affiliate
		orderId
		refUserId
		refUserFullname
		currency
		storeCommissionPercentage
		taxPercentage
		takehomePercentage
	}
`;
