import { NotificationGroups } from "../@admin/notification/notification.constant";
import AdminImg from "./assets/admin.png";
import ReviewImg from "./assets/review.png";
import DiscountImg from "./assets/discount.png";
import StudyPlanImg from "./assets/studyPlan.png";
import QuizImg from "./assets/Quiz.png";
import AppHelpCenterImg from "./assets/appHelpCenter.png";
import OnlineClassImg from "./assets/onlineClass.png";
import AffiliateImg from "./assets/affiliate.png";
import SubscriptionImg from "./assets/subscription.png";
import StreakImg from "./assets/streak.png";
import NewContentImg from "./assets/newContent.png";
import SpeakingImg from "./assets/speaking.png";
import ListeningImg from "./assets/listening.png";
import GrammarImg from "./assets/grammar.png";
import VocabularyImg from "./assets/vocabulary.png";
import ReadingImg from "./assets/reading.png";
import AppUpdateImg from "./assets/appUpdate.png";
import PollImg from "./assets/poll.png";
import NewsIssuesImg from "./assets/newsIssues.png";
import SocialNetworksImg from "./assets/socialNetworks.png";
import OthersImg from "./assets/others.png";

export const isNotificationEnabled = () => {
	if (process.env.NODE_ENV !== "production") {
		return true;
	}

	return !!(window as any).notificationFeatureEnabled;
};

export type ValueOf<T> = T[keyof T];

export const NotificationGroupsImages: Record<
	ValueOf<typeof NotificationGroups>,
	string
> = {
	[NotificationGroups.Admin]: AdminImg,
	[NotificationGroups.Review]: ReviewImg,
	[NotificationGroups.Discount]: DiscountImg,
	[NotificationGroups.StudyPlan]: StudyPlanImg,
	[NotificationGroups.Quiz]: QuizImg,
	[NotificationGroups.AppHelpCenter]: AppHelpCenterImg,
	[NotificationGroups.OnlineClass]: OnlineClassImg,
	[NotificationGroups.Affiliate]: AffiliateImg,
	[NotificationGroups.Subscription]: SubscriptionImg,
	[NotificationGroups.Streak]: StreakImg,
	[NotificationGroups.NewContent]: NewContentImg,
	[NotificationGroups.Speaking]: SpeakingImg,
	[NotificationGroups.Listening]: ListeningImg,
	[NotificationGroups.Grammar]: GrammarImg,
	[NotificationGroups.Vocabulary]: VocabularyImg,
	[NotificationGroups.Reading]: ReadingImg,
	[NotificationGroups.AppUpdate]: AppUpdateImg,
	[NotificationGroups.Poll]: PollImg,
	[NotificationGroups.NewsIssues]: NewsIssuesImg,
	[NotificationGroups.SocialNetworks]: SocialNetworksImg,
	[NotificationGroups.Others]: OthersImg,
};

export const getNotificationImageBasedonGroup = (
	group: ValueOf<typeof NotificationGroups>
) => {
	return NotificationGroupsImages[group];
};
