import React from "react";
import Text from "../../../components/text/Text";
import View from "../../../components/view/View";
import Button from "../../../theme/button/Button";
import { IconWrapper } from "../../../theme/icons/Icon";
import css from "./HomePremiumBanner.module.css";
import { translate } from "../../../i18n";
import { useGoPremium } from "../../../helper/hooks/useGoPremium";
import i18n from "i18n-js";

const HomePremiumBanner = () => {
	const goPremium = useGoPremium();

	const isRNCafeBazaar = (window as any)?.isRNCafeBazaar;

	const showPremiumWithStores =
		!isRNCafeBazaar &&
		i18n.locale === "fa-IR" &&
		(window as any).ReactNativeOpenPremuimModal;

	const faIRGoPremiumWithStores = () => {
		if ((window as any).ReactNativeOpenPremuimModal) {
			(window as any).ReactNativeOpenPremuimModal();
		}
	};

	const isIos = (window as any).platformOs === "ios";

	return (
		<div className={css.wrap}>
			<View className={css.root} onClick={goPremium}>
				<View className={css.text}>
					<Text type="title" className={css.title}>
						{translate("premiumBanner.title")}
					</Text>
					<Text className={css.desc}>
						{translate("premiumBanner.subtitle")}
					</Text>
				</View>
				<Button className={css.actionBtn}>
					<IconWrapper name="crown" />
					<span className={css.btnText}>
						{translate("premiumBanner.btn")}
					</span>
				</Button>
			</View>
			{showPremiumWithStores && (
				<div className={css.buyIntl}>
					<Button
						onClick={faIRGoPremiumWithStores}
						color="default"
						className={css.actionBtn2}
					>
						<span className={css.btnText2}>
							{isIos
								? translate("profile.metaOraPlusAppStore")
								: translate("profile.metaOraPlusGooglePlay")}
						</span>
					</Button>
				</div>
			)}
		</div>
	);
};

export default HomePremiumBanner;
