import AddNoteIcon from "./icons/AddNoteIcon";
import ArrowLeftIcon from "./icons/ArrowLeftIcon";
import ArrowRightIcon from "./icons/ArrowRightIcon";
import DoneIcon from "./icons/DoneIcon";
import EditIcon from "./icons/EditIcon";
import FullscreenIcon from "./icons/FullscreenIcon";
import LanguageEditIcon from "./icons/LanguageEditIcon";
import LanguageIcon from "./icons/LanguageIcon";
import MessageRemoveIcon from "./icons/MessageRemoveIcon";
import MuteIcon from "./icons/MuteIcon";
import PauseIcon from "./icons/PauseIcon";
import PlaybackSpeedIcon from "./icons/PlaybackSpeed";
import PlayIcon from "./icons/PlayIcon";
import PlusIcon from "./icons/PlusIcon";
import RemoveIcon from "./icons/RemoveIcon";
import RepeatIcon from "./icons/RepeatIcon";
import VolumeIcon from "./icons/VolumeIcon";
import NoteIcon from "./icons/NoteIcon";
import VisibleIcon from "./icons/VisibleIcon";
import HiddenIcon from "./icons/HiddenIcon";
import ArrowNextIcon from "./icons/ArrowNextIcon";
import AddImageIcon from "./icons/AddImageIcon";
import BoldIcon from "./icons/BoldIcon";
import ImageIcon from "./icons/ImageIcon";
import NotFoundIcon from "./icons/NotFoundIcon";
import UserVoiceIcon from "./icons/UserVoiceIcon";
import LampIcon from "./icons/LampIcon";
import SortIcon from "./icons/SortIcon";
import UserIcon from "./icons/UserIcon";
import ReviewIcon from "./icons/ReviewIcon";
import CourseListIcon from "./icons/CourseListIcon";
import HomeIcon from "./icons/HomeIcon";
import MenuIcon from "./icons/MenuIcon";
import LogoIcon from "./icons/LogoIcon";
import AvatarIcon from "./icons/AvatarIcon";
import LatestCoursesIcon from "./icons/LatestCoursesIcon";
import ReviewLessonIcon from "./icons/ReviewLessonIcon";
import NotificationIcon from "./icons/NotificationIcon";
import ClockIcon from "./icons/ClockIcon";
import VideoFillIcon from "./icons/VideoFillIcon";
import SpeakFillIcon from "./icons/SpeakFillIcon";
import BookFillIcon from "./icons/BookFillIcon";
import WriteFillIcon from "./icons/WriteFillIcon";
import OrderFillIcon from "./icons/OrderFillIcon";
import GrammerFillIcon from "./icons/GrammerFillIcon";
import ConversationFillIcon from "./icons/ConversationFillIcon";
import PronunciationFillIcon from "./icons/PronunciationFillIcon";
import CheckboxIcon from "./icons/CheckboxIcon";
import NoUserIcon from "./icons/NoUserIcon";
import CloseIcon from "./icons/CloseIcon";
import ExitIcon from "./icons/ExitIcon";
import PrivacyIcon from "./icons/PrivacyIcon";
import GeneralIcon from "./icons/GeneralIcon";
import KeyIcon from "./icons/KeyIcon";
import BookCloseIcon from "./icons/BookCloseIcon";
import NativeLanguageIcon from "./icons/NativeLanguageIcon";
import LockBigIcon from "./icons/LockBigIcon";
import LogoBigIcon from "./icons/LogoBigIcon";
import LockForgetIcon from "./icons/LockForgetIcon";
import CheckEmailBigIcon from "./icons/CheckEmailBigIcon";
import ArrowDownIcon from "./icons/ArrowDownIcon";
import LanguageBigIcon from "./icons/LanguageBigIcon";
import UploadIcon from "./icons/UploadIcon";
import QuestionMarkIcon from "./icons/QuestionMark";
import StarIcon from "./icons/StarIcon";
import ArrowCircleIcon from "./icons/ArrowCircleIcon";
import CharacterIcon from "./icons/CharacterIcon";
import NeedleIcon from "./icons/NeedleIcon";
import AdjustIcon from "./icons/AdjustIcon";
import BackwardIcon from "./icons/BackwardIcon";
import ForwardIcon from "./icons/ForwardIcon";
import ZoomIcon from "./icons/ZoomIcon";
import SubtitleIcon from "./icons/SubtitleIcon";
import ZoomOutIcon from "./icons/ZoomOutIcon";
import RefreshIcon from "./icons/RefreshIcon";
import CompleteIcon from "./icons/CompleteIcon";
import LikeIcon from "./icons/LikeIcon";
import StarFlatIcon from "./icons/StarFlatIcon";
import BugIcon from "./icons/BugIcon";
import ChatIcon from "./icons/ChatIcon";
import LockIcon from "./icons/LockIcon";
import RotateIcon from "./icons/RotateIcon";
import UnlockIcon from "./icons/UnlockIcon";
import CloseModalIcon from "./icons/CloseModalIcon";
import ProgressIcon from "./icons/ProgressIcon";
import CrownIcon from "./icons/CrownIcon";
import GemIcon from "./icons/GemIcon";
import AdsIcon from "./icons/AdsIcon";
import TagIcon from "./icons/TagIcon";
import StreakIcon from "./icons/StreakIcon";
import CourseIcon from "./icons/CourseIcon";
import UserNewIcon from "./icons/UserNewIcon";
import HappyIcon from "./icons/HappyIcon";
import ComingSoonIcon from "./icons/ComingSoonIcon";
import MusicIcon from "./icons/MusicIcon";
import CartoonIcon from "./icons/CartoonIcon";
import FilmIcon from "./icons/FilmIcon";
import EducationIcon from "./icons/EducationIcon";
import DocumentIcon from "./icons/DocumentIcon";
import NotStartedIcon from "./icons/NotStartedIcon";
import GoogleIcon from "./icons/GoogleIcon";
import SMSIcon from "./icons/SMSIcon";
import AppleIcon from "./icons/AppleIcon";
import PeopleIcon from "./icons/PeopleIcon";
import HumanitiesIcon from "./icons/HumanitiesIcon";
import SpeakIcon from "./icons/SpeakIcon";
import SpeakSlowIcon from "./icons/SpeakSlowIcon";

export const icons = {
	play: PlayIcon,
	playbackSpeed: PlaybackSpeedIcon,
	fullscreen: FullscreenIcon,
	mute: MuteIcon,
	pause: PauseIcon,
	volume: VolumeIcon,
	language: LanguageIcon,
	languageEdit: LanguageEditIcon,
	repeat: RepeatIcon,
	addNote: AddNoteIcon,
	arrowLeft: ArrowLeftIcon,
	arrowRight: ArrowRightIcon,
	plus: PlusIcon,
	edit: EditIcon,
	done: DoneIcon,
	remove: RemoveIcon,
	messageRemove: MessageRemoveIcon,
	note: NoteIcon,
	visible: VisibleIcon,
	hidden: HiddenIcon,
	arrowNext: ArrowNextIcon,
	addImage: AddImageIcon,
	bold: BoldIcon,
	image: ImageIcon,
	notFound: NotFoundIcon,
	userVoice: UserVoiceIcon,
	lamp: LampIcon,
	sort: SortIcon,
	user: UserIcon,
	review: ReviewIcon,
	course: CourseListIcon,
	home: HomeIcon,
	menu: MenuIcon,
	logo: LogoIcon,
	avatar: AvatarIcon,
	latestCourses: LatestCoursesIcon,
	reviewLesson: ReviewLessonIcon,
	notification: NotificationIcon,
	clock: ClockIcon,
	videoFill: VideoFillIcon,
	speakFill: SpeakFillIcon,
	bookFill: BookFillIcon,
	writeFill: WriteFillIcon,
	orderFill: OrderFillIcon,
	grammerFill: GrammerFillIcon,
	conversationFill: ConversationFillIcon,
	pronunciationFill: PronunciationFillIcon,
	checkbox: CheckboxIcon,
	noUserIcon: NoUserIcon,
	close: CloseIcon,
	exit: ExitIcon,
	privacy: PrivacyIcon,
	general: GeneralIcon,
	key: KeyIcon,
	bookClose: BookCloseIcon,
	nativeLanguage: NativeLanguageIcon,
	lockBig: LockBigIcon,
	logoBig: LogoBigIcon,
	lockForget: LockForgetIcon,
	checkEmailBig: CheckEmailBigIcon,
	arrowDown: ArrowDownIcon,
	languageBig: LanguageBigIcon,
	upload: UploadIcon,
	questionMark: QuestionMarkIcon,
	star: StarIcon,
	arrowCircle: ArrowCircleIcon,
	character: CharacterIcon,
	needle: NeedleIcon,
	adjust: AdjustIcon,
	backward: BackwardIcon,
	forward: ForwardIcon,
	zoom: ZoomIcon,
	subtitle: SubtitleIcon,
	zoomOut: ZoomOutIcon,
	refresh: RefreshIcon,
	complete: CompleteIcon,
	like: LikeIcon,
	starFlat: StarFlatIcon,
	bug: BugIcon,
	chat: ChatIcon,
	lock: LockIcon,
	rotate: RotateIcon,
	unlock: UnlockIcon,
	closeModal: CloseModalIcon,
	progress: ProgressIcon,
	crown: CrownIcon,
	gem: GemIcon,
	ads: AdsIcon,
	tag: TagIcon,
	streak: StreakIcon,
	courseList: CourseIcon,
	userNew: UserNewIcon,
	happy: HappyIcon,
	comingSoon: ComingSoonIcon,
	music: MusicIcon,
	cartoon: CartoonIcon,
	film: FilmIcon,
	education: EducationIcon,
	document: DocumentIcon,
	notStarted: NotStartedIcon,
	google: GoogleIcon,
	sms: SMSIcon,
	apple: AppleIcon,
	people: PeopleIcon,
	humanities: HumanitiesIcon,
	speak: SpeakIcon,
	speakSlow: SpeakSlowIcon,
} as const;

export type IconName = keyof typeof icons;

export type IconProps = {
	children?: never;
	color?: string;
	className?: string;
	size?: number;
	width?: number;
	height?: number;
	extraColors?: string[];
};
