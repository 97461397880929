import { matchPath } from "react-router-dom";
import { currentLessonRewardEarnedVar } from "../App";

export const PROFILE_PATH = "/profile";

export const SETTINGS_PATH = "/settings";

export const COURSE_PATH = "/course";

export const SUBCOURSE_PATH = `${COURSE_PATH}/:courseId/subcourse`;

export const CHAPTER_PATH = `${COURSE_PATH}/:courseId/:subcourseId/chapter`;

export const LESSON_PATH = `${COURSE_PATH}/:courseId/:subcourseId/:chapterId/lesson`;

export const REVIEW_PATH = "/review";

export const DICTIONARY_PATH = "/dictionary";

export const RoutesConfig = {
	//free
	home: {
		path: "/",
	},
	profile: {
		//free
		root: {
			path: PROFILE_PATH,
		},
		//free
		changePassword: {
			path: `${PROFILE_PATH}/change-password`,
		},
		//free
		deleteAccount: {
			path: `${PROFILE_PATH}/delete-account`,
		},
	},
	settings: {
		//free
		root: {
			path: SETTINGS_PATH,
		},
		//free
		textToSpeech: {
			path: `${SETTINGS_PATH}/text-to-speech`,
		},
		//free
		notifications: {
			path: `${SETTINGS_PATH}/notifications`,
		},
	},
	course: {
		add: {
			path: `${COURSE_PATH}/add`,
		},
		list: {
			path: "/courses",
		},
		edit: {
			path: `${COURSE_PATH}/edit/:courseId`,
		},
	},
	subcourse: {
		add: {
			path: `${SUBCOURSE_PATH}/add`,
		},
		list: {
			path: `${SUBCOURSE_PATH}/list`,
		},
		edit: {
			path: `${SUBCOURSE_PATH}/edit/:subcourseId`,
		},
	},
	chapter: {
		add: {
			path: `${CHAPTER_PATH}/add`,
		},
		list: {
			path: `${CHAPTER_PATH}/list`,
		},
		edit: {
			path: `${CHAPTER_PATH}/edit/:chapterId`,
		},
	},
	lesson: {
		add: {
			path: `${LESSON_PATH}/add`,
		},
		list: {
			path: `${LESSON_PATH}/list`,
		},
		edit: {
			path: `${LESSON_PATH}/edit/:lessonId`,
		},
		subtitle: {
			path: `${LESSON_PATH}/subtitle/:lessonId`,
		},
		single: {
			path: `${LESSON_PATH}/single/:lessonId/:unit?`,
		},
		main: {
			path: `${LESSON_PATH}/main/:lessonId/:unit?`,
		},
		speaking: {
			path: `${LESSON_PATH}/speaking/:lessonId/:unit?`,
		},
		vocabulary: {
			path: `${LESSON_PATH}/vocabulary/:lessonId/:unit?`,
		},
		vocabList: {
			path: `${LESSON_PATH}/vocab-list/:lessonId/:unit?`,
		},
		writing: {
			path: `${LESSON_PATH}/writing/:lessonId/:unit?`,
		},
		orderGame: {
			path: `${LESSON_PATH}/order-game/:lessonId/:unit?`,
		},
	},
	review: {
		root: {
			path: REVIEW_PATH,
		},
		lesson: {
			path: `${REVIEW_PATH}/:courseId/:lessonId`,
		},
	},
	dictionary: {
		root: {
			path: DICTIONARY_PATH,
		},
		word: {
			path: `${DICTIONARY_PATH}/:wordId`,
		},
	},
	notification: {
		list: {
			path: "/notifications/list",
		},

		single: {
			path: "/notifications/:notificationId",
		},
	},
};

export const getLessonPagesHeaders = (rewardEarned?: {
	earned: 0 | 1;
	lessonId: string;
	unit?: number;
}) => {
	const pages = [
		RoutesConfig.lesson.single.path,
		RoutesConfig.lesson.main.path,
		RoutesConfig.lesson.speaking.path,
		RoutesConfig.lesson.vocabulary.path,
		RoutesConfig.lesson.writing.path,
		RoutesConfig.lesson.orderGame.path,
	];

	const url = new URL(window.location.href);

	let headers: Partial<{
		"x-custom-unit": number;
		"x-custom-lesson-id": string;
		"x-custom-reward-earned": 0 | 1;
	}> = {};

	pages.forEach((path) => {
		const match: any = matchPath(url.pathname, {
			path,
			exact: true,
			strict: false,
		});

		console.log("----match---", match);

		if (match && match.params) {
			const lessonId = match.params?.lessonId;

			headers = {
				"x-custom-unit": rewardEarned?.unit || 1,
				"x-custom-lesson-id": match.params?.lessonId,
			};

			if (lessonId && rewardEarned?.lessonId === lessonId) {
				headers["x-custom-reward-earned"] = rewardEarned?.earned || 1;
			}
		}
	});

	//reset after setting the headers
	currentLessonRewardEarnedVar(undefined);

	return headers;
};
