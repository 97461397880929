import { MutationFunctionOptions, useMutation } from "@apollo/client";
import FAIL_TRANSACTION from "./fail-transaction.gql";
import {
	FailTransactionData,
	FailTransactionVariables,
} from "./fail-transaction.types";
import useErrorNotification from "../../../../helper/hooks/useErrorNotification";
import { useMemo } from "react";

export function useFailTransactionMutation() {
	const [failTransaction, { loading, data }] = useMutation<
		FailTransactionData,
		FailTransactionVariables
	>(FAIL_TRANSACTION);

	/*const dataForNotify = useMemo(() => {
		const res = {
			result: !!data?.failTransaction.result,
			errors: data?.failTransaction?.errors,
		};

		return data ? res : undefined;
	}, [data]);

	useErrorNotification(dataForNotify);*/

	function failTransactionHandler(
		variables: FailTransactionVariables,
		options?: MutationFunctionOptions<
			FailTransactionData,
			FailTransactionVariables
		>
	) {
		console.log("FailTransactionAPIRequested", "Buy Plan API Requested!");
		failTransaction({
			variables,
			...options,
		});
	}

	return {
		loading,
		failTransaction: failTransactionHandler,
		data,
	};
}
