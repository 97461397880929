import { SERVER_URL } from "../config";
import { getUserCountry } from "./user-location.utility";
import { getOSInfo } from "./user-os-info.utitlity";

export type UserTrackingProps = {
	country: string;
	country_3: string;
	ip: string;
	countryName: string;
	platform: "ios" | "android" | "web" | "other";
	version?: string; // iOS or Android version
	browser: "chrome" | "firefox" | string; // more browser options can be added as needed
	browserVersion: string;
	device: "mobile" | "tablet" | "desktop";
	isRealDevice?: boolean;
	timeZone: string;
	regionName?: string;
	zipCode?: string;
	languages: readonly string[];
	screenWidth: number;
	screenHeight: number;
	battery?: {
		charging: boolean;
		level: number;
	};
	hardwareConcurrency: number;
	deviceMemory: number;
	brand?: string;
	model?: string;
	affiliate?: string | null;
	utm_source?: string | null;
	utm_medium?: string | null;
};

interface BatteryManager {
	charging: boolean;
	level: number;
}

// Helper function to retrieve battery information
function getBatteryInfo() {
	if ("getBattery" in navigator) {
		return (navigator as any)
			.getBattery()
			.then((battery: BatteryManager) => ({
				charging: battery.charging,
				level: battery.level,
			}));
	} else {
		return "Battery API not supported";
	}
}

export const getUserTrackingInfo = async () => {
	// Fetch IP and country using geojs.io
	const geoData = await getUserCountry();
	console.log("---geoData---", geoData);
	const ip = geoData.ip;
	const country = geoData.country;
	const country_3 = geoData.country_code3 || "Unknown"; // geojs doesn't provide country code3, add if available

	const { os, version } = getOSInfo();

	// Other information
	const countryName = geoData.name; // or any country name lookup
	const platform = os === "desktop" ? "web" : os || "other";
	const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const screenWidth = window.screen.width;
	const screenHeight = window.screen.height;
	const browser =
		(navigator as any).userAgentData?.brands?.[0]?.brand ||
		navigator.userAgent;
	const browserVersion =
		(navigator as any).userAgentData?.brands?.[0]?.version || "Unknown";
	const languages = navigator.languages;
	const deviceMemory = (navigator as any).deviceMemory || "Unknown";
	const hardwareConcurrency = navigator.hardwareConcurrency || "Unknown";
	const device = /Mobi|Android/i.test(navigator.userAgent)
		? "mobile"
		: "desktop";

	// Battery info (if supported)
	let battery: BatteryManager | undefined;
	if ("getBattery" in navigator) {
		battery = await getBatteryInfo();
	}

	return {
		country,
		country_3,
		ip,
		countryName,
		platform,
		version: version ? `${version}` : undefined,
		timeZone,
		screenWidth,
		screenHeight,
		browser,
		browserVersion,
		device,
		languages,
		battery,
		hardwareConcurrency,
		deviceMemory,
	} as UserTrackingProps;
};

export function saveUserTrackingInfo(userTrackingData: UserTrackingProps) {
	console.log("---userTrackingData--mmm-", userTrackingData);
	fetch(`${SERVER_URL}/user-tracking`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(userTrackingData),
		//mode: 'no-cors', // Ensures CORS requests
	})
		.then((response) => {
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			return response.json();
		})
		.then((data) => {
			console.log("User info saved successfully:", data);
		})
		.catch((error) => {
			console.error("Error saving user info:", error);
		});
}
