import { gql } from "@apollo/client";

const IS_REQUEST_READY = gql`
	mutation IsRequestReady($id: ID!, $showResult: Boolean) {
		isRequestReady(id: $id, showResult: $showResult) {
			task {
				id
				status
				result
			}
			errors
		}
	}
`;

export default IS_REQUEST_READY;
