import { gql } from "@apollo/client";

const GET_USER_NOTIFICATIONS_COUNT = gql`
	query GetUserNotificationsCount(
		$s: String
		$searchBy: NotificationSearchBy
		$status: NotificationStatus
		$startDate: String
		$endDate: String
		$group: [String]
	) {
		getUserNotificationsCount(
			s: $s
			searchBy: $searchBy
			status: $status
			startDate: $startDate
			endDate: $endDate
			group: $group
		) {
			totalCount
			errors
		}
	}
`;

export default GET_USER_NOTIFICATIONS_COUNT;
