import { QueryHookOptions, useQuery } from "@apollo/client";
import GET_USER_NOTIFICATIONS_COUNT from "./get-user-notifications-count.gql";
import {
	GetUserNotificationsCountData,
	GetUserNotificationsCountVariables,
} from "./get-user-notifications-count.types";

export function useGetUserNotificationsCountQuery(
	options?: QueryHookOptions<
		GetUserNotificationsCountData,
		GetUserNotificationsCountVariables
	>
) {
	const { loading, data, fetchMore } = useQuery<
		GetUserNotificationsCountData,
		GetUserNotificationsCountVariables
	>(GET_USER_NOTIFICATIONS_COUNT, {
		fetchPolicy: "cache-and-network",
		...options,
	});

	return {
		loading,
		data,
		fetchMore,
	};
}
