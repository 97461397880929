import React, { useCallback } from "react";
import { useRNHandler } from "../@utility/react-native.utility";
import { useCBConfirmTransactionMutation } from "../../containers/ecommerce/graphQL/cb-confirm-transaction/cb-confirm-transaction.mutation";
import { useFailTransactionMutation } from "../../containers/ecommerce/graphQL/fail-transaction/fail-transaction.mutation";
import useErrorNotification, {
	openErrorNotification,
} from "../../helper/hooks/useErrorNotification";
import { useReactiveVar } from "@apollo/client";
import { isRTLVar, userInfoVar } from "../../App";
import { CurrentUser } from "../../graphQL/user/UserTypes";
import { updateUserInfo } from "../../helper/utilities";
import { USER_SUBSCRIPTION_PREMIUM } from "../../graphQL/user/UserConstants";
import { useHistory } from "react-router-dom";
import { translate } from "../../i18n";

export const RNCafeBazaarPayment = () => {
	const { loading: cbConfirmTransactionLoading, cbConfirmTransaction } =
		useCBConfirmTransactionMutation();

	const userInfo = useReactiveVar(userInfoVar);

	const history = useHistory();

	const CafeBazaarConfirmPayment = (
		purchaseToken: string,
		productId: string
	) => {
		cbConfirmTransaction(
			{
				purchaseToken,
				productId,
			},
			{
				onCompleted({ CBConfirmTransaction }) {
					if (CBConfirmTransaction.result && userInfo) {
						const currentUserInfo: CurrentUser = {
							...userInfo,
							subscription: USER_SUBSCRIPTION_PREMIUM,
						};

						updateUserInfo({
							needToRnLogin: true,
							currentUserInfo,
							prevUserInfo: userInfo,
						});

						history.push("/");
					}
				},
			}
		);
	};

	const cbSuccessPayment = useCallback(
		(payload: { purchaseToken: string; productId: string }) => {
			// fail to remove video
			// no need to do anything
			CafeBazaarConfirmPayment(payload.purchaseToken, payload.productId);
		},
		[]
	);

	useRNHandler("cbSuccessPayment", cbSuccessPayment);

	const { failTransaction, loading: failTransactionLoading } =
		useFailTransactionMutation();

	const isRTL = useReactiveVar(isRTLVar);

	const cbFailPayment = useCallback(
		(payload: { transactionId: string; description?: string }) => {
			let description = "";
			switch (payload.description) {
				case "Error: Item not found":
					description = translate("cbPayment.notFountError");
					break;
				case "Error: We can't communicate with Bazaar: Service is disconnected":
					description = translate("cbPayment.CommunicateError");
					break;
				case "Error: Bazaar is not installed":
					description = translate("cbPayment.installError");
					break;
				case "Error: SDK is not connected to bazaar!":
					description = translate("cbPayment.sdkError");
					break;
				case "Error: purchase canceled":
					description = translate("cbPayment.cancelError");
					break;
				case "Error: CB Subscription failed.":
				default:
					description = translate("cbPayment.generalError");
			}

			openErrorNotification(description, isRTL);

			failTransaction(
				{
					transactionId: payload.transactionId,
					description: payload.description,
				},
				{
					onCompleted({ failTransaction }) {
						if (failTransaction.result) {
							// do something here
						}
					},
				}
			);
		},
		[]
	);

	useRNHandler("cbFailPayment", cbFailPayment);

	return null;
};
