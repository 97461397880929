import { gql } from "@apollo/client";

export const USER_FIELDS_FRAGMENT = gql`
	fragment UserFields on User {
		id
		fullname
		email
		username
		phoneNumber
		role
		nativeLanguage
		registeredCourses
		capabilities
		createdAt
		updatedAt
		subscription
		currentStreak
		lastDailyGoalReached
		dailyGoalAmount
		dailyGoalType
		avatar
		authenticationModel
		fcmTokens
		studyTimeReminder
		hasUsedTrialVersion
		affiliate
		claimNoAffiliate
		myAffiliateId
		verified
		emailVerified
		phoneVerified
		status
		pushNotifTokens {
			installationId
			token
		}
		languageLevel
		subscriptionPlan {
			plan
			name
			title
			startDate
			endDate
			amount
			transactionId
			status
		}
	}
`;
