let fetchingPromise: any = null; // Tracks the ongoing fetch request

// Function to fetch user's country based on IP address
export async function getUserCountry() {
	// If there is already a fetch in progress, return the promise of that fetch
	if (fetchingPromise) {
		return fetchingPromise;
	}

	try {
		// Start the fetch and store the promise in fetchingPromise
		fetchingPromise = fetch("https://get.geojs.io/v1/ip/country.json")
			.then((response) => response.json())
			.then((data) => {
				return data;
			})
			.finally(() => {
				// Clear the fetching promise after it completes
				fetchingPromise = null;
			});

		return fetchingPromise;

		/**
		 * {
				"ip": "2a01:4f9:c011:9fa7::1",
				"name": "Finland",
				"country": "FI",
				"country_3": "FIN"
			}
		 */

		//return data.name;
	} catch (error) {
		console.error("Error fetching user country:", error);
		return null;
	}
}
