import { EmbededMedia } from "../../../graphQL/media/MediaTypes";
import { SortDirection } from "../../affiliate/affiliate.types";

// Enums for sorting and searching notifications
export enum NotificationSortBy {
	NAME = "name",
	TITLE = "title",
	READ_AT = "readAt",
	CREATED_AT = "createdAt",
	UPDATED_AT = "updatedAt",
}

export enum NotificationSearchBy {
	NAME = "name",
	TITLE = "title",
	SUMMARY = "summary",
}

export enum NotificationStatus {
	READ = "read",
	UNREAD = "unread",
}

// System action for notifications
export interface NotificationSystemAction {
	title: string;
	actionType: string;
	actionData?: string;
}

// Notification type definition
export interface Notification {
	id: string;
	name: string;
	title: string;
	summary: string;
	group?: string;
	status: NotificationStatus;
	originCampaign?: string;
	systemActions: NotificationSystemAction[];
	readAt?: string;
	userId: string;
	createdAt: string;
	updatedAt: string;
}

// List of notifications
export interface NotificationList {
	notifications?: Notification[];
	totalCount: number;
	errors?: string[];
}

export interface GetAllNotificationsVars {
	sort?: SortDirection;
	sortBy?: NotificationSortBy;
	first?: number;
	offset?: number;
	s?: string; // Search string
	searchBy?: NotificationSearchBy;
	status?: NotificationStatus;
	startDate?: string;
	endDate?: string;
	userId?: string;
	group?: string[];
}

export interface GetUserNotificationsVars {
	sort?: SortDirection;
	sortBy?: NotificationSortBy;
	first?: number;
	offset?: number;
	s?: string;
	searchBy?: NotificationSearchBy;
	status?: NotificationStatus;
	startDate?: string;
	endDate?: string;
	group?: string[];
}

// In-App Notification Item List

export type NotificationMessageImageItem = {
	type: "image";
	media: EmbededMedia & { type: "image" };
};

export type NotificationMessageVideoItem = {
	type: "video";
	media: EmbededMedia & { type: "video" };
};

export type NotificationMessageAudioItem = {
	type: "audio";
	media: EmbededMedia & { type: "audio" };
};

export type NotificationMessageGalleryItem = {
	type: "gallery";
	media: (EmbededMedia & { type: "image" })[];
};

export type NotificationMessageTextItem = {
	type: "text";
	content?: string;
	contentType: "rawText" | "editorText";
};

export type NotificationMessageActionItem = {
	type: "action";
	title: string;
	actionType: "link" | "copy" | "other" | "openAnotherItems";
	actionData?: string;
	isClicked?: boolean;
};

export type NotificationMessageBaseItem = {
	id: string;
	relatedActionIds?: string[];
};

export type NotificationMessage = NotificationMessageBaseItem &
	(
		| NotificationMessageImageItem
		| NotificationMessageVideoItem
		| NotificationMessageAudioItem
		| NotificationMessageGalleryItem
		| NotificationMessageTextItem
		| NotificationMessageActionItem
	);
