import React from "react";
import { CurrentUser } from "../../graphQL/user/UserTypes";
import { userInfoVar } from "../../App";
import { useReactiveVar } from "@apollo/client";

let timeout: NodeJS.Timeout;

type CurrUserTime = CurrentUser & {
	time: string;
};

const useReactNativeUserSync = () => {
	const [rnNewUserInfoSync, setRnNewUserInfoSync] = React.useState<
		CurrUserTime | undefined
	>();

	const userInfo = useReactiveVar(userInfoVar);

	React.useEffect(() => {
		if (timeout) {
			clearInterval(timeout);
		}

		timeout = setInterval(() => {
			if (
				(window as any).ReactNativeInjectedUser &&
				rnNewUserInfoSync?.time !==
					(window as any).ReactNativeInjectedUser?.time
			) {
				const currentUserInfo = {
					// just for fixing curren ios version
					...{
						affiliate: userInfo?.affiliate,
						claimNoAffiliate: userInfo?.claimNoAffiliate,
						myAffiliateId: userInfo?.myAffiliateId,
						verified: userInfo?.verified,
						emailVerified: userInfo?.emailVerified,
						phoneVerified: userInfo?.phoneVerified,
						status: userInfo?.status,
						languageLevel: userInfo?.languageLevel,
						subscriptionPlan: {
							...(userInfo?.subscriptionPlan || {}),
						},
					},
					...((window as any).ReactNativeInjectedUser || {}),
				};

				localStorage.setItem(
					"currentUserInfo",
					JSON.stringify(currentUserInfo)
				);

				userInfoVar(currentUserInfo);

				setRnNewUserInfoSync(currentUserInfo);
			}
		}, 100);

		return () => {
			clearInterval(timeout);
		};
	}, [rnNewUserInfoSync?.time]);

	return null;
};

export default useReactNativeUserSync;
