import { gql } from "@apollo/client";

const FAIL_TRANSACTION = gql`
	mutation FailTransaction($transactionId: ID!, $description: String) {
		failTransaction(
			transactionId: $transactionId
			description: $description
		) {
			result
			errors
		}
	}
`;

export default FAIL_TRANSACTION;
