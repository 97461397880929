import { gql } from "@apollo/client";

export const NOTIFICATION_FIELDS_FRAGMENT = gql`
	fragment NotificationFields on Notification {
		id
		name
		title
		summary
		group
		status
		createdAt
		updatedAt
	}
`;
