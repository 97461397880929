import { MutationFunctionOptions, useMutation } from "@apollo/client";
import CB_CONFIRM_TRANSACTION from "./cb-confirm-transaction.gql";
import {
	CBConfirmTransactionData,
	CBConfirmTransactionVariables,
} from "./cb-confirm-transaction.types";
import useErrorNotification from "../../../../helper/hooks/useErrorNotification";
import { useMemo } from "react";

export function useCBConfirmTransactionMutation() {
	const [cbConfirmTransaction, { loading, data }] = useMutation<
		CBConfirmTransactionData,
		CBConfirmTransactionVariables
	>(CB_CONFIRM_TRANSACTION);

	const dataForNotify = useMemo(() => {
		const res = {
			result: !!data?.CBConfirmTransaction.result,
			errors: data?.CBConfirmTransaction?.errors,
		};

		return data ? res : undefined;
	}, [data]);

	useErrorNotification(dataForNotify);

	function cbConfirmTransactionHandler(
		variables: CBConfirmTransactionVariables,
		options?: MutationFunctionOptions<
			CBConfirmTransactionData,
			CBConfirmTransactionVariables
		>
	) {
		console.log(
			"CBConfirmTransactionAPIRequested",
			"Buy Plan API Requested!"
		);
		cbConfirmTransaction({
			variables,
			...options,
		});
	}

	return {
		loading,
		cbConfirmTransaction: cbConfirmTransactionHandler,
		data,
	};
}
