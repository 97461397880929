// Types for OS information
interface OSInfo {
	os: "android" | "ios" | "desktop" | null;
	version: number | null;
}

// Helper function to get the OS and version
export const getOSInfo = (): OSInfo => {
	if (typeof navigator !== "undefined") {
		const userAgent =
			navigator.userAgent ||
			navigator.vendor ||
			("opera" in window ? ((window as any).opera as string) : null);
		let os: OSInfo["os"] = null;
		let version: OSInfo["version"] = null;

		if (userAgent) {
			// Detect Android
			if (/android/i.test(userAgent)) {
				os = "android";
				const match = userAgent.match(/Android\s([0-9.]*)/);
				version = match ? parseFloat(match[1]) : null;
			}
			// Detect iOS
			else if (
				/iPad|iPhone|iPod/.test(userAgent) &&
				!(window as any).MSStream
			) {
				os = "ios";
				const match = userAgent.match(/OS\s([0-9_]*)/);
				version = match ? parseFloat(match[1].replace("_", ".")) : null;
			}
			// Detect Desktop
			else {
				os = "desktop";
			}
		} else {
			os = "desktop";
		}

		return { os, version };
	}

	// Default return if navigator is not available
	return { os: null, version: null };
};
